import Dashboard from "./views/Dashboard.js";
import Users from "./views/users.js";
import Houses from "./views/houses.js";
import Agents from "./views/agents.js";
/* import SMS from "./views/sms.js"; */
import ScheduleSms from "./views/scheduleSms.js";
//import ManageSmsTemplate from "./views/manageSmsTemplate.js";
import ViewSms from "./views/viewSms.js";
import Payments from "./views/payments";
//import SmsGroup from "./views/smsGroup";
import Profile from "./views/common/UserProfile";
import Collections from "./views/collections"
import UnpaidHouses from "./views/unpaidHouses"
import Region from "./views/region"
import RegionHouses from './views/regionHouses'
import UnpaidResidence from './views/unpaidResidence'
import reconciliation from './views/reconciliation'
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    visible:true
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/admin",
    visible:true
  },{
    path: "/region",
    name: "Regions",
    icon: "tim-icons icon-bank",
    component: Region,
    layout: "/admin",
    visible:true
  },
  {
    path: "/agents",
    name: "Agents",
    icon: "tim-icons icon-badge",
    component: Agents,
    layout: "/admin",
    visible:true
  },
  /* {
    path: "/regionHouses",
    name: "Region Houses", 
    icon: "tim-icons icon-bank",
    component: RegionHouses,
    layout: "/admin",
    visible:true
  }, */
  {
    path: "/houses",
    name: "Houses",
    icon: "tim-icons icon-bank",
    component: Houses,
    layout: "/admin",
    visible:true
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "tim-icons icon-coins",
    component: Payments,
    layout: "/admin",
    visible:true
  },
  {
    path: "/unpaidHouses",
    name: "House Payments",
    icon: "tim-icons icon-coins",
    component: UnpaidHouses,
    layout: "/admin",
    visible:true
  },
  {
    path: "/reconciliation",
    name: "Payment Reconciliation",
    icon: "tim-icons icon-coins",
    component: reconciliation,
    layout: "/admin",
    visible:true
  },
  
  {
    path: "/collections",
    name: "Collections",
    icon: "tim-icons icon-single-02",
    component: Collections,
    layout: "/admin",
    visible:true
  },
  {
    path: "/sms",
    name: "SMS",
    icon: "tim-icons icon-chat-33",
    component: ViewSms,
    layout: "/admin",
    visible:true
    /* subRoutes:[
      
    ] */
  },  
  /* {
    path: "/sms_groups",
    name: "SMS GROUPS",
    icon: "tim-icons icon-single-02",
    component: SmsGroup,
    layout: "/admin",
    visible:true
  }, */
 /*  {
    path: "/manage_template",
    name: "SMS TEMPLATE",
    icon: "tim-icons icon-single-02",
    component: ManageSmsTemplate,
    layout: "/admin",
    visible:true
  }, */
  /* {
    path: "/schedule_sms",
    name: "SCHEDULE SMS",
    icon: "tim-icons icon-single-02",
    component: ScheduleSms,
    layout: "/admin",
    visible:true
  }, */
  
  {
    path: "/profile",
    name: "Profile",
    icon: "tim-icons icon-single-02",
    component: Profile,
    layout: "/admin",
  }
  
];
export default routes;
