import React , {useState, useEffect} from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
import moment from 'moment'
import Multiselect from 'react-bootstrap-multiselect'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter,InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';
function Sms() { 
  const [Sms , setSms] = useState({rows:[]})
  const [Users , setUsers] = useState({rows:[]})
  const [type, updateType] = React.useState("direct");
  const [message, updateMessage] = React.useState("");
  const [schedule_date, updateScheduleDate] = React.useState("");
  const [contact, updateContacts] = React.useState("");
  const [userList, updateUserList] = React.useState([]);

  const getResource = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      }
    })
    .then( async response => {      
      const data = response.data
      console.log("sms", data.data )
      setter(data.data)
    })
  }
  const getUsers = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      }
    })
    .then( async response => {      
      const data = response.data
      console.log("users", data.data )
      setter(data.data)
      if(data.data.rows.length > 0 ){
        let userArray = []
        data.data.rows.map(item => {
          userArray.push({
            value:item.id + ', ' + item.firstName, 
            selected:false
          })
        })
        updateUserList(userArray)
      }
    })
  }
  const addSms = () => {
    console.log("j", type, message, schedule_date ,  contact)
    let SmsData
    if(type == 'direct') {
      SmsData = [{type, message ,  contact , status :"unprocessed" , schedule_date:moment().format('YYYY-MM-DD HH:mm:ss')}]
    }else if(type == 'multiple' ) {
      let messageList = []
      Users.rows.map(user => {
       if(user.role == 'user') {
         messageList.push(
           {type, message,  contact: user.phone , status :"unprocessed" , schedule_date:moment().format('YYYY-MM-DD HH:mm:ss')}
           )
       }
      })
      SmsData = messageList
      console.log("xxxxx", messageList)
    }
    axios({
      url: window.server + "/sms/bulkSms",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`, 
      },
      data:SmsData
    }).then(async (response) => { 
      const data = response;
      Swal.fire({
        title:  ' Success!',
        text: response.data.message,  
        confirmButtonText:  'ok'
      }) 
      console.log("  Added", data);
      getResource("/sms", setSms);
      setDelModal(false)
      setModal(false)
    });
  };
  const updateUsers = (users) => {
    console.log("j", users)
    Swal.fire({
      title:  ' Success!',
      text: "", 
      confirmButtonText:  'ok'
    }) 
  };
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [paginationSize, updatepaginationSize] = React.useState(5); 
  const [pageStartIndex, updatepageStartIndex] = React.useState(0);
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const Columns = [{
    dataField: 'id',
    text: ' ID'
  }, {
    dataField: 'contact',
    text: 'Phone'
  }, {
    dataField: 'message',
    text: 'Message'
  }, {
    dataField: 'status',
    text: 'Status'
  }, {
    dataField: 'createdAt',
    text: 'Date'
  }];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
    sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      }// A numeric array is also available. the purpose of above example is custom the text
    };
  const toggle = () => setModal(!modal);
  const toggleDel = () => setDelModal(!delmodal);
  useEffect (() => {
    getResource(`/sms?page=${0}&size=${100000}`, setSms)
    getUsers(`/users?page=${0}&size=${100000}`, setUsers)
  }, [])
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Sms</h5>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                  <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle tag="h4">All Sms</CardTitle>
                  <Button
                    className=" btn-neutral btn-round btn-simple"
                    color="default"
                    onClick={() => toggle()}
                  >
                    {" "}
                    Add Sms
                  </Button>
                </div>
              </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ Sms.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
          { ...props.baseProps } 
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal modalClassName="modal-black" isOpen={modal} toggle={toggle}/*  className={className} */> 
            <ModalHeader toggle={toggle}>Add Sms</ModalHeader>
            <ModalBody>
            <Form >
                    <Row>
                    <Col md="12">
                          <FormGroup>
                            <Label>Type</Label>
                            <select    className="form-control"  onChange={(e) => { 
                              updateType(e.target.value)
                              console.log("x" , e.target.value)
                            }}>
                            <option value="Select Region" >- Select Type -</option>
                                <option key={'direct'} value='direct' >
                                direct
                                </option>
                                <option key={'schedule'} value='schedule' >
                                schedule
                                </option>
                                <option key={'multiple'} value='multiple' >
                                All Users
                                </option>
                              </select>
                          </FormGroup>
                        </Col>
                        {/* <Col md="12">
                          <FormGroup>
                            <Label>Type</Label>
                            <Col md="12">
                          <FormGroup>
                            <Label>Users</Label>
                            <Multiselect onChange={updateUsers}  data={userList} multiple />
                          </FormGroup>
                        </Col>
                          </FormGroup>
                        </Col> */}
                        {type === 'schedule' && <Col md="12">
                        <div className="datepicker-container">
                          <FormGroup>
                          <Label>Schedule Date</Label> 
                            <ReactDatetime
                             onChange={updateScheduleDate}
                             value={schedule_date}
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date",
                              }}
                            />
                          </FormGroup>
                        </div>
                        </Col>
              }
                        <Col md="12">
                          <FormGroup>
                            <Label>Message</Label>
                            <Input placeholder="" type="textarea"  onChange={(e) => { updateMessage(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                        {type != 'multiple' &&<Col md="12">
                          <FormGroup>
                            <Label>Contacts</Label>
                            
                            <select    className="form-control"   onChange={(e) => { updateContacts(e.target.value)}}>
                            <option value="Select Region" >- Select User -</option>
                            {Users.rows.map((el, i) => (
                          <option key={i} value={el.phone}>
                            {el.firstName}+ {"   "}+ {el.lastName}
                          </option>
                        ))}
                              </select>
                          </FormGroup>
                        </Col> }
                      </Row>
                    </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      addSms() 
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" />
                  </Button>
                </ModalFooter>
       
        </Modal>
        <Modal modalClassName="modal-black" isOpen={delmodal} toggle={toggleDel}/*  className={className} */>
            <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
            <ModalBody>
                <p>Are You sure You want to delete this sms ? {selectedItem._id} </p>
            </ModalBody>
            <ModalFooter>           
            </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Sms;
