import React  from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/Admin/Admin.js";
import UserLayout from "./layouts/User/User";
import AgentLayout from "./layouts/Agent/Agent";
import Login from "./views/common/login";
import LandingPage from "./views/common/LandingPage.js";
import RegisterPage from "./views/common/RegisterPage";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


/* import ProfilePage from "./views/common/UserProfile.js"; */
import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/scss/blk-design-system-react.scss?v=1.2.0";
import "./assets/demo/demo2.css";
import "./assets/css/nucleo-icons.css";
import "./assets/css/custom.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Alert from './components/alert/alert2'

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
//import axios from "axios";
window.server =   /* "http://localhost:5000/api/v1" // */"https://goodhope.sakataka.co.ke/api/v1";
window.tenantId = 1
window.CompanyNumber = "+254720 396 310"
// check token validity /
/* let checkTokenValidity = () => {
  axios({
    url: `${window.server}/verifyToken`,
    headers: {
      Authorization: localStorage.token,
    },
  })
    .then((response) => {
      // console.log(response);
      if (response.code === 401) {
        window.logout(true);
        setTimeout(() => {
          alert("Your Session has expired");
        }, 0);
      } else {
        setTimeout(() => {
          // console.log("checking validity");
          if (localStorage.token) {
            checkTokenValidity();
          }
        }, 30000);
      }
    })
    .catch((d) => {
      console.log(d);
    });
}; */

window.logout = (force) => {
  let logout = () => {
    let path = window.location.pathname;
    localStorage.clear();
    localStorage.previousPath = path;
    window.location = "/login";
  };

  if (force) {
    logout();
    return false;
  }
};

// check if logged in
if (localStorage.token && localStorage.user) {
 window.user = JSON.parse(localStorage.user);
} else {
}

window.confirmLogout = ()=>{
  console.log("s")
  return window.alert2.confirm({
    icon: <i className="tim-icons icon-single-02" />,
    title: "Are you sure that you want to Logout ?",
    message: "If you agree your session will be terminated",
    confirmText: "Log Out",
    onSubmit: () => {
      window.logout();
    },
  }); 
}
/* const inactivityTime = function () {
  let time;

  let resetTimer = () => {
    clearTimeout(time);
    time = setTimeout(window.logout, 900);
  };

  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
}; */

  if (localStorage.token) {
    console.log("s")
    //checkTokenValidity();
  }
  window.verifyNumber = (n) => {
    n = n + "";
    if (n[0] + n[1] + n[2] === "254") {
      return parseInt(n);
    } else {
      return parseInt("254" + parseInt(n));
    }
  };
ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
      <div className={/* !this.state.blur || */ "blur-alert "}>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/user" render={(props) => <UserLayout {...props} />} />
          <Route path="/agent" render={(props) => <AgentLayout {...props} />} />
          <Route path="/auth" render={(props) => <Login {...props} />} />
          <Route path="/home" render={(props) => <LandingPage {...props} />} />
        {/*   <Route path="/profile" render={(props) => <ProfilePage {...props} />} /> */}
          <Route path="/register" render={(props) => <RegisterPage {...props} />} />
          <Redirect from="/" to="/home" />
        </Switch>
        </div>       
        <Alert
          onRef={(ref) => {
            window.alert2 = ref;
          }}
          toggle={(blur) => {
            this.setState({ blur });
          }}
        ></Alert> 
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
