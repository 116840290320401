import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function Payment({selectedHouse}) {
  const [Residence, setResidence] = useState([]);
  /* const [User, setUser] = useState([]); */
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [description, setDescription] = useState("");
  const [selectedResidence, setSelectedResidence] = useState({});
  const [Amount, setAmount] = useState("");
  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const getResidence = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("residence 222", data.data.rows);
        setter(data.data.rows);
    });
  };
  const addMpesaPayment = () => {
    console.log("User[selectedUser]", selectedUser)
    console.log("selectedResidence", selectedResidence)
    let data = {
      msisdn:selectedResidence.Resident[selectedUser].phone,
      amount: Amount =="" ? selectedResidence.charge : Amount ,
      method: "mpesa",
      user: selectedResidence.Resident[selectedUser].id,
      residence:selectedResidence.id,
      description: description || `Mpesa payment for house  ${selectedResidence.id} by user ${selectedResidence.Resident[selectedUser].id} `,
      initiatedBy: localStorage.getItem("user_id")
    };
    console.log("mpesa", data);
    setModal1(false);
    axios({
      url: window.server + "/payments",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: data,
    })
      .then(async (response) => {
        const data = response.data;
        console.log("region", data);
        window.location = "/admin/payments";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addCashPayment = () => {
    console.log("User[selectedUser]", selectedResidence.Resident[selectedUser])
    console.log("selectedResidence",  selectedResidence)
    let data = {
      msisdn: selectedResidence.Resident[selectedUser].phone,
       amount: Amount =="" ? selectedResidence.charge : Amount ,
      method: "cash",
      user: selectedResidence.Resident[selectedUser].id,
      residence:selectedResidence.id,
      description: description || `Cash payment for house  ${selectedResidence.id} by user ${selectedResidence.Resident[selectedUser].id} `,
      receivedBy: localStorage.getItem("user_id"), 
    };
    console.log("cash", data);
    setModal(false);
    axios({
      url: window.server + "/payments",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: data,
    })
      .then(async (response) => {
        const data = response.data;
        console.log("region", data);
        window.location = "/admin/payments";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    //getResidence(`/Houses?page=${0}&size=${100000}`, setResidence); 
    /* getUser(`/users`, setUser); */
    if(selectedHouse ){
      console.log("received selected house from props ", selectedHouse)
      setSelectedResidence(selectedHouse)
    } 
  }, [selectedHouse]);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className=" btn-round"
          color="primary"
          id="tooltip639225725"
          onClick={() => {
            toggle1();
          }}
        >
          {/* <i className="tim-icons  icon-zoom-split" /> */}
          <p>MPESA</p>
        </Button>
        <Button
          className=" btn-round"
          color="primary"
          id="tooltip639225725"
          onClick={() => {
            toggle();
          }}
        >
          {/* <i className="tim-icons  icon-zoom-split" /> */}
          <p>CASH</p>
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle} /*  className={className} */>
        <ModalHeader toggle={toggle}>Cash Payment</ModalHeader>
        <ModalBody>
          <Form>
            <Row>             

              <Col md="12">
                <FormGroup>
                  <Label>Residence</Label>
                  <h2 style={ { color: 'black' } }>
                    {selectedResidence.number}
                  </h2>
                  {/* <select
                    className="form-control"
                    style={{ color: "black" }}
                    onChange={(e) => {
                      setSelectedResidence(e.target.value);
                    }}
                  >
                    <option value="Select Region">- Select Residence -</option>
                    {Residence.map((el, i) => (
                      <option key={i} value={i}>
                        Building: {el.Building ? el.Building.Name : '' }  ||  House: {"   ( "} {el.number} {" ) "}
                      </option>
                    ))}
                  </select> */}
                </FormGroup>
              </Col>
              { Object.keys(selectedResidence).length > 0 && <Col md="12">
                <FormGroup>
                  <Label>User</Label>
                  <select
                    className="form-control"
                    style={{ color: "black" }}
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                  >
                    <option value="Select Region">- Select User -</option>
                    {selectedResidence.Resident.map((el, i) => (
                      <option key={i} value={i}>
                        {el.firstName}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              }
              <Col md="12">
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    placeholder="(optional)"
                    type="textarea"
                    style={{ color: "black" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
              {Object.keys(selectedResidence).length > 0 &&
                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    placeholder="300"
                    type="Number"
                    style={{ color: "black" }}
                    defaultValue= {selectedResidence.charge}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormGroup>
              }
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className=" btn-round"
            color="primary"
            id="tooltip639225725"
            onClick={() => {
              addCashPayment();
            }}
          >
            <i className="tim-icons  icon-simple-add" /> Receive  Payment
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} /*  className={className} */>
        <ModalHeader toggle={toggle1}>Mpesa Payment</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <p>
                  An mpesa payment request will be sent to the user you select
                  below
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Residence</Label>
                  <h2 style={ { color: 'black' } }>
                    {selectedResidence.number}
                  </h2>
                  {/* <select
                    className="form-control"
                    style={{ color: "black" }}
                    onChange={(e) => {   
                      console.log(`Residence`, e.target.value)               
                      setSelectedResidence(e.target.value);
                      console.log(`Residence`, Residence[e.target.value])
                    }}
                  >
                    <option value="Select Residence">- Select Residence -</option>
                    {Residence.map((el, i) => (
                      <option key={i} value={i}>
                        Building: {el.Building ? el.Building.Name : '' }  ||  House: {"   ( "} {el.number} {" ) "}
                      </option>
                    ))}
                  </select> */}
                </FormGroup>
              </Col>
              { Object.keys(selectedResidence).length > 0 && <Col md="12">
                <FormGroup>
                  <Label>User</Label>
                  <select
                    className="form-control"
                    style={{ color: "black" }}
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                  >
                    <option value="Select Region">- Select User -</option>
                    {selectedResidence.Resident.map((el, i) => (
                      <option key={i} value={i}>
                        {el.firstName}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              }
              <Col md="12">
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    placeholder="(optional)"
                    type="textarea"
                    style={{ color: "black" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
              {Object.keys(selectedResidence).length > 0 &&
                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    placeholder="300"
                    type="Number"
                    style={{ color: "black" }}
                    defaultValue= {selectedResidence.charge}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormGroup>
              }
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className=" btn-round"
            color="primary"
            id="tooltip639225725"
            onClick={() => {
              addMpesaPayment();
            }}
          >
            <i className="tim-icons  icon-simple-add" /> Initiate Payment 
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Payment;
