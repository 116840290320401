import React, { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
} from "reactstrap";

function LoginPage() {
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);

  const login = () => {

    if (!phone  || !password ) {
        Swal.fire({
        title:  'Error!',
        text: 'Missing Credentials',
        confirmButtonText:  'ok'
      }) 
      return  
    }

    axios({
      url:window.server + "/auth/login",
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId
      },
      data: {phone: window.verifyNumber(phone).toString(), password: (password).toString()},
      })
      .then((response) => {
        response = response.data
        if (response.token) {
          console.log("user", response)
          localStorage.token = response.token;
          localStorage.user_id = response.user.id;
          localStorage.user_name = response.user.firstName + " " +response.user.lastName ;
          localStorage.user_phone = response.user.phone;
          let location
          if(response.user.role === 'admin') {
            console.log("admin")
             location= "/admin/dashboard";
          }else if(response.user.role === 'agent'){
            console.log("agent")
            location= "/agent/dashboard";
          }else {
            console.log("user")
            location= "/user/dashboard";
          }
         // if (localStorage.previousPath) location = localStorage.previousPath;
          console.log(location)
         window.location = location;
        }
      })
      .catch((error , body) => {
        console.log("error", error );
        if (error.response.data.errors) {
          Swal.fire({
            title:  'Error!',
            text: error.response.data.errors[0].msg ,
            confirmButtonText:  'ok'
          }) 
        }else {
          Swal.fire({
            title:  'Error!',
            text:error.response.data.message,
            confirmButtonText:  'ok'
          }) 
        }
      });
  };
 
  return (
    <>
      <div className="content" style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Col sm="12" md={{ size: 6 }}>
          <Form
          style={{marginTop:'30vh',}}
          onSubmit={e => {
            e.preventDefault();
            login();
          }}
          >
            <Card>
              <CardHeader style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h5 className="title">Login</h5>
              </CardHeader>
              <CardBody style={{margin:'0 30px'}}>
                
                  <Row>
                    <Col className="px-md-1" sm="12">
                      <FormGroup>
                        <label>phone</label>
                        <Input
                          placeholder="0700 000000"
                          type="text"
                          onChange={(e) => { setPhone(e.target.value)}}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-1" sm="12">
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          placeholder="********"
                          type="text"
                          onChange={(e) => { setPassword(e.target.value)}}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit">
                  Login
                </Button>
              </CardFooter>
            </Card>
            </Form>
          </Col>
      </div>
    </>
  );
}

export default LoginPage;
