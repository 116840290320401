import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminPayment from "./common/AdminPayment";
import Receipt from "./common/receipt";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Swal from 'sweetalert2'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';
function Payments() {
  const [payments, setPayments] = useState({rows:[]});
  const [houses, setHouses] = useState({rows:[]});
  const [paginationSize, updatepaginationSize] = React.useState(5); 
  const [pageStartIndex, updatepageStartIndex] = React.useState(0);
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
    console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row  >
      {row.status &&<Button
      style={{padding:'5px'}}
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
       setReceiptModal(true)
      }}
    >
      Receipt
    </Button>}
      <Button
      style={{padding:'5px'}}
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
        setEditModal(true);
      }}
    >
      <i className="tim-icons  icon-pencil" /> 
    </Button>
    <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
        setDelModal(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
    </Button>
    </Row>
    );
}; 
const actionsFormatter1 = (cell, row) => {
  console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
  return (
    <Row >
  <Button
    className=" btn-round btn-sm"
    color="primary"
    id="tooltip639225725"
    onClick={() => {
      //setOccupantEdit(true);
    }}
  >
    <i className="tim-icons  icon-pencil" /> 
  </Button>
  </Row>
  );
};
const expandRow = {
  onlyOneExpanding: true,
  renderer: (row) => (
    <div>
      <p>{`House Attached -`}</p>
      {row.House ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                             data={ row.House } columns={ Column2 } /> : ''}
      <p>{`Customer Attached -`}</p>
      {row.Customer ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                             data={ row.Customer } columns={ Column3 } /> : ''}
    </div>
  ),
};
  const Columns = [{
    dataField: 'id',
    text: 'ID'
  }, 
  {
    dataField: 'createdAt',
    text: 'Date', 
    formatter: (cell) => {
      return moment(new Date(cell).toLocaleDateString()).format("DD/MM/YYYY")
    }
  },{
    dataField: 'reference',
    text: 'Reference'
  }, {
    dataField: 'transactionId',
    text: 'Transaction Id'
  }, {
    dataField: 'method',
    text: 'Method'
  }, {
    dataField: 'amount',
    text: 'Amount'
  }, {
    dataField: 'msisdn',
    text: 'Msisdn'
  }, {
    dataField: 'description',
    text: 'Description'
  },{
    dataField: 'status',
    text: 'Status'
  } ,
  {dataField: "actions",
  text: "Actions",
  formatter: actionsFormatter
}];
const Column2 = [
  {
    dataField: "id",
    text: "House Id",
  },
  {
    dataField: "number",
    text: "Number",
  },
  {
    dataField: "active",
    text: "Active",
  }
];
const Column3 = [
  {
    dataField: "id",
    text: "User Id",
  },
  {
    dataField: "firstName",
    text: "First Name",
  },
  {
    dataField: "lastName",
    text: "Last Name",
  },
  {
    dataField: "phone",
    text: "Phone",
  }, 
  {
    dataField: "active",
    text: "Active",
  }
];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
  sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use", data.data);
      setter(data.data);
    });
  };
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [NewAmount, setNewAmount] = useState(null);
  const [NewStatus, setNewStatus] = useState(null);
  const [NewHouse, setNewHouse] = useState(null);
  const toggle = () => setModal(!modal);
  const toggleDel = () => setDelModal(!delmodal);
  const toggleEdit = () => setEditModal(!editModal);
  const toggleReceipt = () => setReceiptModal(!receiptModal);
  const  updatePayment = () =>{
    let data = {}
    NewAmount != null ?  data.amount = NewAmount : data = data
    NewStatus !=  null? data.status = NewStatus : data = data
    NewHouse !=  null ? data.house = NewHouse :data = data
    axios({
       url:window.server + `/payments/`+selectedItem.id,
       method: "PUT",      
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type": "application/json",
         "X-TENANT-ID": window.tenantId,
         Authorization: `Bearer ${localStorage.token}`,
       },
       data: data
     })
     .then( async response => {      
       const data = response
       setEditModal(false)
       Swal.fire({
         title:  ' Success!',
         text: response.data.message, 
         confirmButtonText:  'ok'
       }) 
       getResource('/payments', setPayments)
     }).catch(error => {
       console.log("error", error);
       Swal.fire({
         title:  'Error!',
         text: error.message,
         confirmButtonText:  'ok'
       }) 
     })
   }
  useEffect(() => {
    getResource(`/payments?page=${0}&size=${100000000}`, setPayments);
    getResource(`/houses?page=${0}&size=${100000000} `, setHouses);
  }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardTitle tag="h4">All Payments</CardTitle>
                       {/*  <Button
                          className=" btn-neutral btn-round btn-simple"
                          color="default"
                          onClick={() => toggle()}
                        >
                          {" "}
                          Add Payment
                        </Button> */}
                      </div>
                    </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ payments.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
        expandRow={expandRow}
          { ...props.baseProps }
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle} /*  className={className} */>
          <ModalHeader toggle={toggle}>Add Payment</ModalHeader>
          <ModalBody>
            <AdminPayment />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          isOpen={delmodal}
          toggle={toggleDel} /*  className={className} */
        >
          <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
          <ModalBody>
            <p>
              Are You sure You want to delete this payment ? {selectedItem._id}{" "}
            </p>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          isOpen={receiptModal}
          toggle={toggleReceipt}
          modalClassName="modal-info "
          style={{ maxWidth: "900px" }}
        >
          <ModalHeader toggle={toggleReceipt}>Receipt</ModalHeader>
          <ModalBody>
            <Receipt props={selectedItem} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          modalClassName="modal-black"
        >
          <ModalHeader toggle={toggleEdit}>Edit Payment</ModalHeader> 
          <ModalBody>
          <p>Editing Payment {selectedItem.id} </p>
                <Form >
                    <Row>
                    <Col md="12">
                          <FormGroup>
                            <Label>Transaction Id</Label>
                            <InputGroup>
                              <Input placeholder={selectedItem.transactionId} type="text" disabled  />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Reference</Label>
                            <InputGroup>
                              <Input placeholder={selectedItem.reference} type="text" disabled  />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Amount</Label>
                            <InputGroup>
                              <Input placeholder={selectedItem.amount} type="text"  onChange={(e) =>  setNewAmount(e.target.value) } disabled />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Status</Label> 
                            <select    className="form-control"  onChange={(e) => { setNewStatus(e.target.value)  }}>
                            <option value="" >- Select status -</option>
                            <option value="cancelled" >cancelled</option>
                            <option value="pending" >pending</option>
                            <option value="processed" >processed</option>
                            <option value="received" >received</option>
                              </select>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>House</Label>
                            <select    className="form-control"  onChange={(e) => { setNewHouse(e.target.value)}}>
                            <option value="Select Region" >- Select house -</option>
                              {houses.rows.map((el, i) => (
                                <option key={i} value={el.id} >
                                  {el.number}
                                </option>
                              ))}
                              </select>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <Button
                    className=" btn-round"
                    color="danger"
                    id="tooltip639225725"
                    onClick={() => {
                      updatePayment() 
                    }}
                  >
                    <i className="tim-icons icon-pencil" />
              Update Record
                  </Button>
                  </Col>
                    </Row>
                  </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Payments;
