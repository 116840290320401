import React  from "react";
import {
  Row,
  Col,
  Table, Button
} from "reactstrap";
import moment from "moment";
import logoImage from "../../assets/img/map.png";
import ReactToPdf from "react-to-pdf";
const thStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal", 
};


function Receipt({props}) {
  console.log("props", props);
  const logo={
    outterLink: "/admin/dashboard",
    text: "Saka Taka",
    imgSrc: logoImage,
   }
  const logoImg = (
    <a
      href={"https://sakataka.co.ke"}
      className="simple-text logo-mini"
      
    >
      <div className="logo-img">
        <img src={logoImage} alt="react-logo" style={{maxWidth:"200px", maxHeight:"200px"}}/>
      </div>
    </a>
  );
  const logoText = (
    <>
    <h2
    
    >
      {logo.text}
    </h2>
    </>)
    const logoAddress = (
      <>
      <h4
      
      >
        {"P.O Box 1243 0052 , Nairobi"}
      </h4>
      </>)
      const logoWebsite = (
        <>
        <h4
        
        >
          {"sakataka.co.ke"}
        </h4>
        </>)
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [5,8]
};
const { reference, amount,method , createdAt , House , Customer ,PaymentCollection} = props
const selectedHouse = props.House ?props.House.find(item => {return item.id = item.PaymentCollection.HouseId || null}) : props.PaymentCollection.HouseId || null
const selectedUser = props.Customer ?props.Customer.find(item => {return item.id = item.PaymentCollection.UserId || null}) :props.PaymentCollection.UserId || null
  return (    
    <div>
      
      <ReactToPdf  filename="receipt.pdf"   options={options}  x={.5} y={.5} scale={0.8}>
        {({toPdf ,targetRef }) => (
      <>
        <div ref={targetRef}  style={{marginLeft:'20px'}}>
        <Row >
        <Col md="4">
        <div className="logo">
                {logoImg}
              </div>
        </Col>
        <Col md="4">
        <div className="logo">
                {logoText}<hr></hr>
                {logoAddress}
                <hr></hr>
                {logoWebsite}
        </div>
        </Col>
        <Col md="4">
        <div style={{display: 'flex', flexDirection:"column-reverse" , flexStart:"bottom"}}>
                <h4 >Date: {moment(props.createdAt).format('MM/DD/YYYY') ||" ........."}</h4>
              </div>
        </Col>
        </Row>
        <Row >
        <Col md="12">
        <div className="logo">
          <h4 >Mr/Mrs/Ms: {selectedUser? selectedUser.firstName  : "SakaTaka User"}</h4>
          </div>
          </Col>
        </Row>
        <Row>
      <Col md="12">
     <Table className="table-bordered table-hover table-dark" responsive style={thStyle}>
        <thead  className="text-white">
          <th>Id</th>
          <th>Description</th>  
          <th>Payment Mode</th>        
          <th>Shs.</th>
          <th>Cts.</th>
        </thead>
        <tbody >
          <tr>
            <td>{props.id ||" ........."}</td>
            <td><p>Garbage collection payment for house number { selectedHouse? selectedHouse.number : " N/A"}</p></td>
            <td>{props.method ||" ........."}</td>
            <td>{props.amount ||" ........."}</td>
            <td>{"00"}</td>
          </tr>
        </tbody>
      </Table> 
      </Col>
      </Row>
      <Row>
      <Col md="6">
        <p style={{ color: "black" }}>Thank You for doing business with us</p>
      </Col>      
      </Row>
        </div>
        <Row>
          {/* <Col md="6">
            <Button color="success" onClick={toPdf}>Print Receipt</Button>
          </Col> */}
          <Col md="6">
            <Button color="success"  onClick={toPdf}>Download Receipt</Button> 
          </Col>
        </Row>
        </>
        )}
        </ReactToPdf>
    </div>
  );
}

export default Receipt;
