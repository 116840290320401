import React , {useState, useEffect} from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  CustomInput,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter,InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';
function Agents() {
  const [agents , setAgents] = useState({rows:[]})
  const [paginationSize, updatepaginationSize] = React.useState(5); 
  const [pageStartIndex, updatepageStartIndex] = React.useState(0);
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
    console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row >
      <Button
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setEditModal(true);
      }}
    >
      <i className="tim-icons  icon-pencil" /> 
    </Button>
    <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setDelModal(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
    </Button>
    </Row>
    );
};
const actionsFormatter1 = (cell, row) => {
  console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
  return (
    <Row >
  <Button
    className=" btn-round btn-sm"
    color="danger"
    id="tooltip639225725"
    onClick={() => {
      setRegionDelModal(true);
    }}
  >
    <i className="tim-icons  icon-trash-simple" /> 
  </Button>
  </Row>
  );
};
  const Columns = [{
    dataField: 'id',
    text: 'User ID'
  }, {
    dataField: 'firstName',
    text: 'First Name'
  }, {
    dataField: 'lastName',
    text: 'Last Name'
  }, {
    dataField: 'phone',
    text: 'Phone'
  }, 
  {
    dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter
}];
  const Column2 = [
    {
      dataField: "id",
      text: "Region Id",
    },
    {
      dataField: "Name",
      text: "Name",
    },
    {
      dataField: "latitude",
      text: "Latitude",
    },
    {
      dataField: "longitude",
      text: "Longitude",
    },
    {
      dataField: "active",
      text: "Active",
    },
  {
    dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter1
}
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
    sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      }// A numeric array is also available. the purpose of above example is custom the text
    };
  
  const getResource = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      }
    })
    .then( async response => {      
      const data = response.data
      console.log("use", data.data )
      setter(data.data)
    })
  }
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [ regionDelModal, setRegionDelModal]= useState(false);
  const [assignmodal, setAssignModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleAssign = () => setAssignModal(!modal);
  const toggleDel = () => setDelModal(!delmodal);
  const toggleRegionDel = () => setRegionDelModal(!regionDelModal);

  const [firstName , updateFirstName] = React.useState("");
  const [lastName , updateLastName] = React.useState("");
  const [phone, updatePhone] = React.useState("");
  const [email, updateEmail] = React.useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [ region, setRegions] = useState({rows:[]})
  const [editmodal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal(!editmodal);
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <p>{`Assigned Region   - ${row.Region.length}`}</p>
        {row.Region ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                             data={ row.Region } columns={ Column2 } /> : ''}
      </div>
    ),
  };
  const addAgent = () => {
    if (!(phone !== ""  && firstName !== "" && lastName !== "")) {
      alert("Please fill in all the required values");
      return false;
    }
    console.log({"firstName": firstName,"lastName":lastName ,  "phone":window.verifyNumber(phone) })
    axios({
      url:window.server + "/Users/",
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: {
          "firstName": firstName,
          "lastName":lastName,
         "phone":window.verifyNumber(phone) ,
          //"password": "password" ,
          "email": email , 
          "role":"agent",
          "region":selectedRegion},
      })
      .then((response) => {
        response = response.data
        getResource('/Users/?role=agent', setAgents) 
      })
      .catch((error) => {
        console.log("error", error);
      }); 
  };
  const assignAgent = () => {
    if ((selectedAgent == null && selectedRegion == null )) {
      alert("Please fill in all the required values");
      return false;
    }
    axios({
      url:window.server + "/assignedRegions/",
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: {UserId: selectedAgent, RegionId: selectedRegion},
      })
      .then((response) => {
        response = response.data
        Swal.fire({
          title:  ' Success!',
          text: response.message, 
          confirmButtonText:  'ok'
        }) 
        setAssignModal(false)
        getResource('/Users/?role=agent', setAgents) 
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          title:  'Error!',
          text: "Agent Already Assigned",
          confirmButtonText:  'ok'
        })
        
      }); 
  };
  const DeleteAgent= () => {
    axios({
      url: window.server + "/Users/" + selectedItem._id,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response;
      console.log("useR deleted", data);
      getResource("/Users/?role=agent", setAgents);
      setDelModal(false)
    });
  };
  useEffect (() => {
    getResource(`/Users/?role=agent&page=${0}&size=${10000000}`, setAgents)  
    getResource(`/regions?page=${0}&size=${1000000}`, setRegions)
  }, [])
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                  <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle tag="h4">All Agents</CardTitle>
                  <div>
                  <Button
                    className=" btn-neutral btn-round btn-simple"
                    color="default"
                    onClick={() =>  toggle() }
                  >
                    {" "}
                    Add Agent
                  </Button>
                  <Button
                    className=" btn-neutral btn-round btn-simple"
                    color="default"
                    onClick={() => window.location = '/admin/collections'}
                  >
                    {" "}
                    Agent Collections
                  </Button>
                  <Button
                    className=" btn-neutral btn-round btn-simple"
                    color="default"
                    onClick={() => toggleAssign()}
                  >
                    {" "}
                    Reassign  Agents
                  </Button>
                  </div>
                </div>
              </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ agents.rows }
  columns={ Columns }
  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
          { ...props.baseProps }
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal modalClassName="modal-black" isOpen={modal} toggle={toggle}/*  className={className} */>
            <ModalHeader toggle={toggle}>Add Agent</ModalHeader>
            <ModalBody>
            <Form >
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label>First Name</Label>
                            <Input placeholder="John" type="text"  onChange={(e) =>  updateFirstName(e.target.value)  }/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Last Name</Label>
                            <Input placeholder="Doe" type="text"  onChange={(e) =>  updateLastName(e.target.value)  }/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>email</Label>
                            <Input placeholder="agent@sakataka.co.ke" type="email"  onChange={(e) => { updateEmail(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Phone</Label>
                            <Input placeholder="0754673457" type="text"  onChange={(e) => { updatePhone(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Region</Label>
                            <select    className="form-control"  onChange={(e) => { setSelectedRegion(e.target.value)}}>
                            <option value="Select Region" >- Select Region -</option>
                              {region.rows.map((el, i) => (
                                <option key={i} value={el._id} >
                                  {el.Name}
                                </option>
                              ))}
                              </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      addAgent() 
                    }}
                  >
                    <i className="tim-icons  icon-simple-add" />
                    Add Agent
                  </Button>
                </ModalFooter>
        
        </Modal>
        
        <Modal modalClassName="modal-black" isOpen={assignmodal} toggle={toggleAssign}/*  className={className} */>
            <ModalHeader toggle={toggleAssign}>Assign Agent</ModalHeader>
            <ModalBody>
            <Form >
                    <Row>
                    <Col md="12">
                          <FormGroup>
                            <Label>Agent</Label>
                            <select    className="form-control"  onChange={(e) => { setSelectedAgent(e.target.value)}}>
                            <option value="Select Region" >- Select Agent -</option>
                              {agents.rows.map((el, i) => (
                                <option key={i} value={el.id} >
                                  {el.firstName}
                                </option>
                              ))}
                              </select>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Region</Label>
                            <select    className="form-control"  onChange={(e) => { setSelectedRegion(e.target.value)}}>
                            <option value="Select Region" >- Select Region -</option>
                              {region.rows.map((el, i) => (
                                <option key={i} value={el.id} >
                                  {el.Name}
                                </option>
                              ))}
                              </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      assignAgent() 
                    }}
                  >
                    <i className="tim-icons  icon-simple-add" />
                    Assign Agent
                  </Button>
                </ModalFooter>
        
        </Modal>
        <Modal modalClassName="modal-black" isOpen={delmodal} toggle={toggleDel}/*  className={className} */>
            <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
            <ModalBody>
                <p>Are You sure You want to delete this agent ? {selectedItem._id} </p>
            </ModalBody>
            <ModalFooter>
            <Button
              className=" btn-round btn-simple"
              color="default"
              onClick={() => {
                DeleteAgent()
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-remove" />Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal modalClassName="modal-black" isOpen={regionDelModal} toggle={toggleRegionDel}/*  className={className} */>
            <ModalHeader toggle={toggleRegionDel}>Confirmation</ModalHeader>
            <ModalBody>
                <p>Are You sure want t detach this agent from the region  ? {selectedItem._id} </p>
            </ModalBody>
            <ModalFooter>
            <Button
              className=" btn-round btn-simple"
              color="default"
              onClick={() => {
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-remove" />Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={editmodal}
          toggle={toggleEdit} /*  className={className} */
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <p>
              Edit
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              onClick={() => {
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-pencil" />
              Edit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Agents;
