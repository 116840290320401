import React, { useState, useEffect } from "react";
import axios from "axios";
import Receipt from './common/receipt'
import moment from 'moment';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
  CardTitle,
  Table, Modal, ModalHeader, ModalBody, ModalFooter , Button
} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit'; 
function Payments() {
  const [payments, setPayments] = useState({rows:[]});

  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use", data.data);
      setter(data.data)
    });
  };
  const [receiptModal, setReceiptModal] = useState(false);
  const toggleReceipt = () => setReceiptModal(!receiptModal);
  const [selectedItem, setSelectedItem] = useState(false);
  const [paginationSize, updatepaginationSize] = React.useState(5)
  const [pageStartIndex, updatepageStartIndex] = React.useState(0)
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
    console.log(); // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row  >
      {row.status &&<Button
      style={{padding:'5px'}}
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
       setReceiptModal(true)
      }}
    >
      Receipt
    </Button>}
    </Row>
    );
}; 
  const Columns = [{
    dataField: 'id',
    text: 'ID'
  }, 
  {
    dataField: 'createdAt',
    text: 'Date', 
    formatter: (cell) => {
      return moment(new Date(cell).toLocaleDateString()).format("DD/MM/YYYY")
    }
  },{
    dataField: 'reference',
    text: 'Reference'
  }, {
    dataField: 'transactionId',
    text: 'Transaction Id'
  }, {
    dataField: 'method',
    text: 'Method'
  }, {
    dataField: 'amount',
    text: 'Amount'
  }, {
    dataField: 'msisdn',
    text: 'Msisdn'
  }, {
    dataField: 'description',
    text: 'Description'
  },{
    dataField: 'status',
    text: 'Status'
  } ,
  {dataField: "actions",
  text: "Actions",
  formatter: actionsFormatter
}];
const Column2 = [
  {
    dataField: "id",
    text: "House Id",
  },
  {
    dataField: "number",
    text: "Number",
  },
  {
    dataField: "active",
    text: "Active",
  }
];
const Column3 = [
  {
    dataField: "id",
    text: "User Id",
  },
  {
    dataField: "firstName",
    text: "First Name",
  },
  {
    dataField: "lastName",
    text: "Last Name",
  },
  {
    dataField: "phone",
    text: "Phone",
  }, 
  {
    dataField: "active",
    text: "Active",
  }
];
const expandRow = {
  onlyOneExpanding: true,
  renderer: (row) => (
    <div>
      <p>{`House Attached -`}</p>
      {row.House ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                             data={ row.House } columns={ Column2 } /> : ''}
      <p>{`Customer Attached -`}</p>
      {row.Customer ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                             data={ row.Customer } columns={ Column3 } /> : ''}
    </div>
  ),
};
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
  paginationSize: paginationSize,
  pageStartIndex: pageStartIndex,
  alwaysShowAllBtns: true, // Always show next and previous button
  withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  sizePerPageList: [{
    text: '5', value: 5
  }, {
    text: '10', value: 10
  } ,{
    text: '20', value: 20
  }, {
    text: '50', value: 50
  }, {
    text: 'All', value: payments.count
  }] // A numeric array is also available. the purpose of above example is custom the text
  };
 
  useEffect(() => { 
    getResource(
      `/payments?receivedBy=${localStorage.user_id}&page=${page}&size=${offset}` ,
      setPayments
    );
  }, []);
  return (
    <>
      <div className="content">
        {payments &&<Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Payments</h5>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">All Payments</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ payments.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
        expandRow={expandRow}
          { ...props.baseProps }
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>}
        {!payments &&<Row>
          <Col md="12">
            <Card style={{display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
              <CardHeader>
              <p style={{ color: "white" }}>
                  Sorry You do not have any payment records
                </p>
              </CardHeader>
              <CardBody>
                
                <ul>
                  <img src={require("../assets/img/anime3.png").default} alt="Anime"></img>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Modal isOpen={receiptModal} toggle={toggleReceipt}  modalClassName="modal-info " style={{maxWidth: "900px"}} >
            <ModalHeader toggle={toggleReceipt}>Receipt</ModalHeader>
            <ModalBody>
                <Receipt props={selectedItem}/>
            </ModalBody>
            <ModalFooter>           
            </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Payments;
