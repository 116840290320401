import React , {useState} from "react";
import Payment from './common/agentPayment' 

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  UncontrolledCarousel, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
function AgentDashboard() {
    const carouselItems = [
        {
          src: require("../assets/img/denys.jpg").default,
          altText: "Slide 1",
          caption: "Sakataka",
        },
        {
          src: require("../assets/img/fabien-bazanegue.jpg").default, 
          altText: "Slide 2",
          caption: "Sakataka",
        },
        {
          src: require("../assets/img/mark-finn.jpg").default,
          altText: "Slide 3",
          caption: "Sakataka",
        },
      ];

      const [modal, setModal] = useState(false);
      const toggle = () => setModal(!modal);
  return (
    <>
      <div className="content">
        <Row className="justify-content-center pb-3">
              <Col md="6">
                <Row className="justify-content-center align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row> 
              </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card style={{display:'flex', alignItems: 'center', justifyContent: 'center'}} onClick={()=> toggle()/* window.location ='/sms/create_sms */}>
              <CardHeader >
                <h5 className="title">Receive a Payment</h5>
              </CardHeader>
              <CardBody>
                <i className="tim-icons  icon-zoom-split" />
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4" >
            <Card style={{display:'flex', alignItems: 'center', justifyContent: 'center'}} onClick={()=>  window.location ='/agent/collectionS'}>
              <CardHeader >
                <h5 className="title">Collect History</h5>
              </CardHeader>
              <CardBody>
                <i className="tim-icons  icon-zoom-split" />
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4" >
            <Card style={{display:'flex', alignItems: 'center', justifyContent: 'center'}} onClick={()=> console.log("d")/*  window.location ='/sms/view_sms' */}>
              <CardHeader >
                <h5 className="title">Messages</h5>
              </CardHeader>
              <CardBody>
                    <i className="tim-icons  icon-zoom-split" />
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}/*  className={className} */>
            <ModalHeader toggle={toggle}>Payment</ModalHeader>
            <ModalBody>
                <Payment></Payment>
            </ModalBody>
            <ModalFooter>           
            </ModalFooter>
        </Modal>
            
      </div>
    </>
  );
}

export default AgentDashboard;
