import Dashboard from "./views/agentDashboard";
import Profile from "./views/common/UserProfile";
import Payments from "./views/agentCollections";
import Houses from './views/agentResidence'
import UnpaidHouses from './views/agentUnpaidResidence'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/agent",
    visible:true
  }  ,
  {
    path: "/Houses",
    name: "Regions",
    icon: "tim-icons icon-square-pin",
    component: Houses,
    layout: "/agent",
    visible:true
  },
  {
    path: "/UnpaidHouses",
    name: "House Payment",
    icon: "tim-icons icon-square-pin",
    component: UnpaidHouses,
    layout: "/agent",
    visible:true
  },
  {
    path: "/collections",
    name: "Collections",
    icon: "tim-icons icon-coins",
    component: Payments,
    layout: "/agent",
    visible:true
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "tim-icons icon-single-02",
    component: Profile,
    layout: "/agent",
    visible:true
  }
];
export default routes;
