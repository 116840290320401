/// app.js
import React from 'react';
import DeckGL from '@deck.gl/react';
import {LineLayer} from '@deck.gl/layers';
import {StaticMap} from 'react-map-gl';
import {MapView, FirstPersonView} from '@deck.gl/core';

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY29rYXlhIiwiYSI6ImNrcm5scmprdjFrdG0yb2xpZW1qaWNtdG8ifQ.jn56J68DneLJGDmWqKfvKQ';

// Viewport settings
const INITIAL_VIEW_STATE = {
  longitude: 36.89523,
  latitude: -1.29386,
  zoom: 13,
  pitch: 0,
  bearing: 0
};

// Data to be used by the LineLayer


function SearchResidence({data2}) {
  const data = [
    {sourcePosition: [-36.89523, -1.29386], targetPosition: [-122.41669, 37.781]}
  ];
  const layers = [
    new LineLayer({id: 'line-layer', data})
  ];

  return (
    <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers} >
        <MapView id="map" width="100%" height="100%" controller={true}>
        <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
        </MapView>
        <FirstPersonView width="50%" x="50%" fovy={50} />
        </DeckGL>
  );
}

export default SearchResidence;