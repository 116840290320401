import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import axios from 'axios';
import moment from 'moment'
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample3,
  chartExample4,
} from "../variables/charts.js";

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const [accountChartLabel, setAccountChartLabel] = React.useState([]);
  const [accountChartData, setAccountChartData] = React.useState([]);
  const [userChartLabel, setUserChartLabel] = React.useState([]);
  const [userChartData, setUserChartData] = React.useState([]);
  const [dailyChartLabel, setDailyChartLabel] = React.useState([]);
  const [dailyChartData, setDailyChartData] = React.useState([]);
  const [regionChartLabel, setRegionalChartLabel] = React.useState([]);
  const [regionChartData, setRegionalChartData] = React.useState([]);
  const [totalCollections,setTotalCollection] = React.useState([]);
  const [smsBalance,setSmsBalance] = React.useState("KSh 0.00");
  const [AgentsDataset,setAgentsDataset] = React.useState([]);
  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  const AccountChart = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: accountChartLabel,
      datasets: /* [
        {
          label: "Total Collections",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: accountChartData,
        },
      ] */ 
      AgentsDataset,
    };
  };
  const UserChart = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: userChartLabel,
      datasets: [
        {
          label: "My First dataset",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: userChartData,
        },
      ],
    };
  };
  const DailyChart = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
    gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
    gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

    return {
      labels: dailyChartLabel,
      datasets: [
        {
          label: "Collections",
          fill: true,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: gradientStroke,
          borderColor: "#d048b6",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: dailyChartData,
        },
      ],
    };
  };
  const RegionalChart = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels: regionChartLabel,
      datasets: [
        {
          label: "Total Amount",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: regionChartData,
        },
      ],
    };
  };
  const getTotalStats = (endpoint, setter) => {
    //console.log(startDate);
    //console.log(endDate);
    //alert(values);
    let q = {};
    console.log("q", q);
    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log("urlParams", urlParams);

    axios({
      url:window.server + endpoint +`?&${urlParams}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        "Authorization": `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      //console.log("stats", data.data);
      const label = []
      const dataSet = []
      if (data.data.length > 0) {
        data.data.forEach(element => {
          label.push(element.firstName)
          dataSet.push(element.total)
         // console.log("element", element)
        });
        //console.log("label", label)
        //console.log("data", dataSet)
        setDailyChartLabel(label);
        setDailyChartData(dataSet);
        setter(data.data)
       // setTotalCollection(data.data);
      }
    });
  };
  const getMonthlyStats = () => {
    //console.log(startDate);
    //console.log(endDate);
    //alert(values);
    let q = {};
    console.log("q", q);
    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log("urlParams", urlParams);

    axios({
      url:window.server + '/stats/monthly' +`?&${urlParams}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      //console.log("stats", data.data);
      const label = []
      const dataSet = []
      if (data.data.length > 0) {
        
        console.log("data.data", data.data[0])
       const x = await Promise.all( data.data[0].map(element => {
         console.log("element ",element)
          const element_name = element.agent.firstName
          console.log("r", element.agent.firstName)
          let items = []
         /*  label.push(element.firstName)
          dataSet.push(element.total) */
          if(element.payments && element.payments.length > 0){
            element.payments.forEach(item => {
             if (items.length < 1  ) {
               items[element_name] = []
             }
              items.push({"agent":element_name, "month":item.date, "total":item.total_amount})
              console.log("item ..", items)
            })
          }else {
            return [
              items.push({"agent":element_name,"month":element.month, "total":0})
            ]
          }
          return items
          
        }));
        console.log("x", x)
       let allData = []
      let allDaset  = []
        await Promise.all(x.map(item => {
          console.log("items 2", item)
          let Dataset = []
          item.map(el => {
            Dataset.push(el.total)
            allDaset.push(el.total_amount)
          })
          let set = {
            label: `${item[0].agent} Collections`,
            fill: true,
            //backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: Dataset,
          }
         // console.log("dataset", dataset)
          allData.push(set)
        })
        )
        setAgentsDataset(allData)
        setAccountChartData(allDaset)
        /* console.log("label", label)
        console.log("data", dataSet) */
        /* setDailyChartLabel(label);
        setDailyChartData(dataSet);
        setter(data.data) */
       // setTotalCollection(data.data);
      }
    });
  };
  const getSmsBalance= async () => {
    //console.log(startDate);
    //console.log(endDate);
    //alert(values);
   
    try {
    const { data: response } = await axios({
      url:window.server + '/sms/smsBalance', 
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        "Authorization": `Bearer ${localStorage.token}`,
      },
    })
    console.log("sms_data", response)
    setSmsBalance(response.data.Credits)
  } catch (error) {
    console.log('error message: ', error.message)
    console.log('error.response: ', error.response ? error.response.data ? error.response.data : error.response : error)
    return {error}
  }
  };
  useEffect(() => {
    getSmsBalance()
    getTotalStats('/stats/' , setTotalCollection);
    getMonthlyStats()
    setAccountChartLabel([
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "NOV",
      "DEC",
    ]);
   //setAccountChartData ([100, 70, 90, 70, 60]);
    setUserChartLabel([
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "NOV",
      "DEC",
    ]);
    setUserChartData([100, 70, 90, 70, 60]);    
    setRegionalChartLabel(["Benson", "Martin", "Boss", "Martin", "Njunge"]);
    setRegionalChartData([90, 27, 60, 12, 80]);
  }, []);
  //const [PerformanceChartData, setbigChartData] = React.useState("data1");
  if (typeof localStorage.token === "undefined") {
    window.location = "/login";
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Sms Balance</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> {smsBalance}
                </CardTitle>
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Mpesa Balance</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100 KSH
                </CardTitle>
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    {/*  <h5 className="card-category">Total Shipments</h5> */}
                    <CardTitle tag="h2">Performance</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Accounts
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Users
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={
                      setbigChartData === "data1" ? AccountChart : UserChart
                    }
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Daily Collections</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                  3,500 KSH
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar data={DailyChart} options={chartExample3.options} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Monthly Regional Collections</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100 KSH
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line data={RegionalChart} options={chartExample4.options} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
